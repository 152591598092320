import React, {Component} from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import { getImage } from "gatsby-plugin-image"
import Seo from "../../components/layout/seo"
import Hero from "../../components/Global/secondLevelHero/secondLevelHero"
import FullWidthText from "../../components/Global/fullWidthText/fullWidthText"
import FullWidthCTA from "../../components/Global/fullWidthCta/fullWidthCta"
import FullWidthImage from "../../components/Global/fullWidthImage/fullWidthImage"
import Footnotes from "../../components/Global/footnotes/footnotes"
import Footer from "../../components/layout/footer"
import $ from "jquery";
import Vimeo from '@vimeo/player';

class infusionPage extends Component {
  componentDidMount() {
  }
}

infusionPage = ({ data }) => {
    const content = data.allContentfulInfusionAdministration.nodes[0];

    let heroData = {
      breadcrumbs: content.hero.breadcrumb,
      title: content.hero.title,
      content: content.hero.content,
      backgroundImage: content.hero.backgroundImage,
      backPos: "top right"
    }
    
    let videoPlayerData = content.videoPlayer

    let fullWidthCtaData = {
      title: content.relatedPages.title,
      buttons: content.relatedPages.buttons,
      customClass: "video-resource"
    }

    let fullWidthBannerData = {
      imageTitle: content.fullWidthBanner.image.title,
      image: getImage(content.fullWidthBanner.image),
    }
    
    let footnotesData = {
      pageJobCode: content.jobCodeDate,
      footnotesData: content.footerData,
    }

    if(typeof window !== 'undefined'){
      $(function() {
        let options = {
            id: 914741660,
            byline: false,
            portrait: false,
            title: false,
            width: 600,
        };
    
        let videoName = new Vimeo('video1', options);
    
        $('.single-video .timestamps .single-timestamp').click(function () {
            var videoSeek = $(this).attr('date-video-time');
            videoName.setCurrentTime(videoSeek);
            $(this.parentElement.childNodes).removeClass("active");
            $(this).addClass("active");
            videoName.play();
        });
      });
    }

    return (
        <Layout>
            <Seo title="Infusion and administration" />
            <Hero data={heroData}/>
            <FullWidthText data={videoPlayerData} />
            <FullWidthCTA data={fullWidthCtaData} />
            <FullWidthImage data={fullWidthBannerData} />
            <Footnotes data={footnotesData} />
            <Footer/>
        </Layout>
      )
}

export const query = graphql`
  query {
    allContentfulInfusionAdministration {
      nodes {
        hero {
          breadcrumb {
            title
            url
          }
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          backgroundImage {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }
        }
        videoPlayer {
          childMarkdownRemark {
            html
          }
        }
        relatedPages {
          title {
            childMarkdownRemark {
              html
            }
          }
          buttons {
            buttonText {
              childMarkdownRemark {
                html
              }
            }
            buttonUrl
          }
        }
        fullWidthBanner {
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }         
        }
        footerData {
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          id
        }
        jobCodeDate
      }
    }
  }
`
export default infusionPage